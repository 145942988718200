import React from "react"
import { StaticImage } from 'gatsby-plugin-image'

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}


const AboutPage = () => (
  <main style={pageStyles}>
    <title>Auke Bakker</title>
    <h1>Auke.io</h1>
    <p>Here be dragons.</p>
    <StaticImage src="../images/toothless.jpg" alt="Scary dragons!" placeholder="blurred"></StaticImage>
  </main>
)
export default AboutPage